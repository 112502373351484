import React from "react"
import Layout from "../components/layout/layout.js"
import SEO from "../components/layout/seo.js"
import HeroSection from "../components/sections/HeroSection.js"
import PastWorks from "../components/sections/PastWorks.js"
import RecentWorks from "../components/sections/RecentWorks.js"
// import About from "../components/sections/About"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <HeroSection />
      <RecentWorks />
      <PastWorks />
      {/* <About /> */}
    </Layout>
  )
}

export default IndexPage
