import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { keyframes } from "styled-components"

function HeroSection() {
  return (
    <Wrapper>
      <ScrollWrapper>
        <ContentWrapper>
          <ChildWrapper>
            <TextWrapper>
              <Title>
                {" "}
                Hello, my name is <br />
                Kenny Hong & I'm
                <br />
                more than designer.{" "}
              </Title>
              <Link to="/about">
                {" "}
                <Paragraph> Learn more about me > </Paragraph>
              </Link>
            </TextWrapper>
            {/* <Lottie ref={container}></Lottie> */}
            <img src="/images/about/AboutMe.png" alt="About" />
          </ChildWrapper>
        </ContentWrapper>
      </ScrollWrapper>
    </Wrapper>
  )
}

export default HeroSection

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
  background-color: white;
  padding-top: 100px;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;

  @media (max-width: 500px) {
    display: none;
  }
`

const ScrollWrapper = styled.div`
  overflow: auto;
  white-space: nowrap;
`

const ContentWrapper = styled.div`
  background-size: cover;
  display: inline-block;

  img {
    padding-top: 100px;

    @media (max-width: 1500px) {
      display: none;
    }
  }
`

const ChildWrapper = styled.div`
  display: flex;
`

const TextWrapper = styled.div`
  padding-left: 72px;
  margin: auto 0;
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 72px;
  line-height: 130%;
  color: #000;
  opacity: 0.7;
`
const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  padding-top: 48px;
  color: black;

  :hover {
    text-decoration: underline;
  }
`

// const Lottie = styled.div`
//   width: 300px;
//   height: 300px;
//   background-color: red;
//   position: absolute;
// `
