import React from "react"
import styled from "styled-components"
import RecentCards from "./RecentCards"
import { keyframes } from "styled-components"

function RecentWorks() {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Recent works</Title>
          <Paragraph>
            Microsoft projects are disclosable by request only. These are some
            exciting stories behind these projects.
            <br />
            Please
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="mailto: kennyhong@outlook.com"
            >
              {" "}
              contact me{" "}
            </a>{" "}
            for detail.
          </Paragraph>
        </TextWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <RecentCards />
      </ContentWrapper>
    </Wrapper>
  )
}

export default RecentWorks

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
  padding-top: 160px;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 2400px;
  padding: 0 72px;
  max-height: 100%;
`

const TextWrapper = styled.div`
  padding: 0px;
  margin: 0 auto;
  display: grid;
  gap: 16px;
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 72px;
  line-height: 130%;
  color: #000;
  display: flex;
  width: 100%;
  opacity: 70%;
`
const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;

  a {
    opacity: 0.9;

    :hover {
      text-decoration: underline;
    }
  }
`
