import React from "react"
import styled from "styled-components"

const menuData = [
  {
    title: "Microsoft Launcher (Microsoft x Android)",
    year: "2018",
    img: "/images/microsoft/Launcher.png",
    text:
      "Created mobile shell in Android system that is integrated with Microsoft apps and services.",
  },

  {
    title: "Dual Screen UX",
    year: "2020",
    img: "/images/microsoft/Duo.png",
    text:
      "Contributed to the design of Microsoft's first foldable mobile device.",
  },
  {
    title: "Cross Device UX (Windows X Android)",
    year: "Current",
    img: "/images/microsoft/YourPhone.png",
    text:
      "Built a bridge app on Windows with seamless experiences for Android mobile users.",
  },
]

export default function RecentCards(props) {
  return (
    <Wrapper>
      <Project>
        {menuData.map(item => (
          <ContentWrapper>
            <img src={item.img} alt={item.title} />
            <TextWrapper>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </TextWrapper>
          </ContentWrapper>
        ))}
      </Project>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  padding-top: 48px;
`

const Project = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 36px;

  @media (max-width: 1548px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 548px) {
    grid-template-columns: 1fr;
  }
`

const ContentWrapper = styled.div`
  width: 100%;
  padding: 0px;
  margin: 0 auto;

  img {
    width: 100%;
    max-height: 700px;

    :hover {
      cursor: not-allowed;
    }
  }
`

const TextWrapper = styled.div`
  display: grid;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 72px;
  width: 100%;
  margin: 0 auto;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
`

const Text = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
`
