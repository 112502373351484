import React from "react"
import styled from "styled-components"
import PastCards from "./PastCards"
import { keyframes } from "styled-components"

function PastWorks() {
  return (
    <Wrapper>
      <ContentWrapper>
        <TextWrapper>
          <Title>Past works</Title>
          <Paragraph>
            {/* It’s a bit outdated work, but get a skim of how I approach the
            design problem. */}
          </Paragraph>
        </TextWrapper>
      </ContentWrapper>
      <ContentWrapper>
        <PastCards />
      </ContentWrapper>
    </Wrapper>
  )
}

export default PastWorks

const animation = keyframes`
from {opacity: 0; transform: translateY(10px);};
to {opacity: 1; transform: translateY(0px);};
`

const Wrapper = styled.div`
  width: 100%;
  max-width: 2900px;
  padding-top: 160px;
  padding-bottom: 160px;
  animation: ${animation} 1s 0.2s forwards;
  opacity: 0;
`

const ContentWrapper = styled.div`
  padding: 0 72px;
`

const TextWrapper = styled.div`
  padding: 0px;
  margin: 0 auto;
  display: grid;
  gap: 16px;
`

const Title = styled.h1`
  font-weight: bold;
  font-size: 72px;
  line-height: 130%;
  color: #000;
  display: flex;
  width: 100%;
  opacity: 70%;
`
const Paragraph = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
`
