import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const menuData = [
  {
    title: "Trade-in Device",
    text: "2017 | UX/UI",
    link: "/tradein",
    css: {
      backgroundColor: "#F90281",
      backgroundImage: "url(images/past/tqt.gif)",
      backgroundSize: "55%",
      backgroundPosition: "center",
    },
  },
  {
    title: "Change Plan",
    text: "2017 | UX/UI",
    link: "/changeplan",
    css: {
      backgroundColor: "#E20074",
      backgroundImage: "url(/images/past/plan.gif)",
      backgroundSize: "54%",
      backgroundPosition: "center",
    },
  },
  {
    title: "Rooy",
    text: "2015 | UX/UI",
    link: "/rooy",
    css: {
      backgroundColor: "#e7e7e4",
      backgroundImage: "url(/images/past/rooy.gif)",
      backgroundSize: "170%",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center",
    },
  },
  {
    title: "Nordstrom",
    text: "2015 | Usability",
    link: "/nordstrom",
    css: {
      backgroundColor: "#231f20",
      backgroundImage: "url(/images/past/nordstorm.png)",
      backgroundSize: "200%",
      backgroundPosition: "center",
    },
  },
]

export default function PastCards(props) {
  return (
    <Wrapper>
      {menuData.map((item, index) => (
        <ContentWrapper>
          <Link to={item.link} key={index}>
            <ImageWrapper style={item.css} />
            <TextWrapper>
              <Title>{item.title}</Title>
              <Text>{item.text}</Text>
            </TextWrapper>
          </Link>
        </ContentWrapper>
      ))}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: grid;
  padding-top: 50px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @media (max-width: 2300px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1500px) {
    grid-template-columns: 1fr;
  }
`

const ContentWrapper = styled.div`
  max-width: 600px;
  background-color: #fff;
  margin: 20px;
  border-radius: 24px;
  overflow: hidden;
  box-shadow: 13.11px 13.11px 26.2201px rgba(155, 155, 155, 0.25),
    -13.11px -13.11px 26.2201px rgba(232, 232, 232, 0.25);
  transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);

  :hover {
    transform: translateY(-2px);
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1);
    box-shadow: 13.11px 13.11px 26.2201px rgba(155, 155, 155, 0.75),
      -13.11px -13.11px 26.2201px rgba(232, 232, 232, 0.75);
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  height: 700px;
  background-size: 50%;
  background-position: center;
  background-repeat: no-repeat;

  @media (max-width: 1500px) {
    height: 500px;
  }

  @media (max-width: 500px) {
    height: 300px;
  }
`

const TextWrapper = styled.div`
  display: grid;
  gap: 8px;
  padding-top: 24px;
  padding-bottom: 42px;
  margin: 0 auto;
  justify-items: center;
`

const Title = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 130%;
  color: #000;
`

const Text = styled.p`
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 130%;
  color: #000;
  opacity: 0.7;
`
